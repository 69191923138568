import type { TypePromo } from '@pelotoncycle/page-builder';
import { imageFieldsToMediaImageProps } from '@pelotoncycle/page-builder';
import type { JSONReference } from 'apps/freeform/types/utilityTypes';
import type {
  TypeComponent_wwwLink,
  TypeComponentExternalLink,
  TypeComponentImage,
  TypeComponentJsonFields,
  TypeComponentMedia,
  TypeComponentVideo,
  TypeDateTrigger,
  TypeOptimizelyExperiment,
  TypePageConfig,
  TypeProduct,
  TypeVariation,
  TypeComponentJson,
} from '@page-builder/lib/types';
import {
  toMediaProps,
  videoFieldsToMediaVideoProps,
  toProductProps,
  MOBILE_BREAKPOINT_WIDTH,
} from '@page-builder/utils/helpers/reference';
import { parseEntry } from './parseEntry';
import type { TruncatedEntry, ReferenceContentTypes, ParentMetadata } from './types';

export const truncateVariationValue = ({ fields: { value } }: TypeVariation) => {
  if (value?.sys.contentType.sys.id === 'product') {
    return toProductProps((value as TypeProduct).fields);
  }

  if (value?.sys.contentType.sys.id === 'dateTrigger') {
    return (value as TypeDateTrigger).fields;
  }

  if (value?.sys.contentType.sys.id === 'optimizelyExperiment') {
    return (value as TypeOptimizelyExperiment).fields;
  }

  return undefined;
};

export const truncateReference = (reference: ReferenceContentTypes): any => {
  if (reference.sys.contentType.sys.id === 'componentImage') {
    return imageFieldsToMediaImageProps((reference as TypeComponentImage).fields, false, {
      mobile: { width: MOBILE_BREAKPOINT_WIDTH },
    });
  }

  if (reference.sys.contentType.sys.id === 'componentVideo') {
    return videoFieldsToMediaVideoProps((reference as TypeComponentVideo).fields);
  }

  if (reference.sys.contentType.sys.id === 'componentMedia') {
    return toMediaProps(reference as TypeComponentMedia);
  }

  if (reference.sys.contentType.sys.id === 'componentExternalLink') {
    return { url: (reference as TypeComponentExternalLink).fields.url };
  }

  if (reference.sys.contentType.sys.id === 'component_wwwLink') {
    return { url: (reference as TypeComponent_wwwLink).fields.url };
  }

  if (reference.sys.contentType.sys.id === 'pageConfig') {
    return (reference as TypePageConfig).fields.config;
  }

  if (reference.sys.contentType.sys.id === 'product') {
    return toProductProps((reference as TypeProduct).fields);
  }

  if (reference.sys.contentType.sys.id === 'variation') {
    return {
      name: (reference as TypeVariation).fields.name,
      value: truncateVariationValue(reference as TypeVariation),
      control: (reference as TypeVariation)?.fields?.control
        ? truncateReference(
            (reference as TypeVariation).fields.control as ReferenceContentTypes,
          )
        : undefined,
      variations: (reference as TypeVariation)?.fields?.variations
        ? ((reference as TypeVariation)?.fields
            ?.variations as ReferenceContentTypes[]).map(truncateReference)
        : undefined,
    };
  }

  if (reference.sys.contentType.sys.id === 'componentJson') {
    return truncateComponentJsonEntry(reference as TypeComponentJson);
  }

  if (reference.sys.contentType.sys.id === 'promo') {
    return {
      name: (reference as TypePromo).fields.name,
      dataTrigger: (reference as TypePromo).fields.dateTrigger,
      showDealsInNav: (reference as TypePromo).fields.showDealsInNav,
    };
  }
};

const isReference = (ref: Record<string, any>): ref is JSONReference<'Entry'> =>
  ref.id && ref.referenceType === 'Entry';

export const parseWithReferences = (
  referenceMap: Map<string, any>,
  metadata?: ParentMetadata,
) => {
  const parsePayload = (
    payloadValue: TypeComponentJsonFields['payload'],
    trace: string[] = [],
  ): any => {
    if (Array.isArray(payloadValue)) {
      return payloadValue.map((payload, index) =>
        parsePayload(payload, [...trace, `@index ${index}`]),
      );
    }
    if (typeof payloadValue === 'object') {
      if (isReference(payloadValue)) {
        // Reference entry (ReferenceContentTypes - link, media, etc.) was not saved correctly in the "References" field
        if (!referenceMap.has(payloadValue.id)) {
          console.log('Trace: ', trace.join(' > '));

          console.log(
            `${metadata?.component} JSON entry https://app.contentful.com/spaces/7vk8puwnesgc/entries/${metadata?.sysId} is missing https://app.contentful.com/spaces/7vk8puwnesgc/entries/${payloadValue.id} in the "References" field`,
          );

          throw new Error(
            `Reference ${payloadValue.id} not found in referenceMap - Make sure that entry ${payloadValue.id} is added to the "References" field in ${metadata?.sysId} in Contentful`,
          );
        }
        return referenceMap.get(payloadValue.id);
      } else {
        return Object.entries(payloadValue).reduce(
          (parsedPayload, [currentKey, currentValue]) => ({
            ...parsedPayload,
            [currentKey]: parsePayload(currentValue, [...trace, currentKey]),
          }),
          {},
        );
      }
    }
    return payloadValue;
  };

  return parsePayload;
};

export const truncateComponentJsonEntry = (
  entry: TypeComponentJson,
): TruncatedEntry<any> => {
  const { sysId, id, fields, tagLinks = [], spaceId } = parseEntry(entry);
  const { name, component, payload, references = [] } = fields;

  const referenceMap = new Map(
    references.map(reference => [
      reference.sys.id,
      { ...truncateReference(reference as ReferenceContentTypes) },
    ]),
  );

  const parsePayload = parseWithReferences(referenceMap, { sysId, component });
  const parsedPayload = parsePayload(payload);

  return {
    sys: {
      id: sysId,
      contentType: {
        sys: { id },
      },
      space: {
        sys: { id: spaceId },
      },
    },
    metadata: {
      tags: tagLinks.map(({ sys: { id: tagId } }) => tagId),
    },
    fields: {
      name,
      component,
      payload: parsedPayload,
    },
  };
};
